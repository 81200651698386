import zhHans from 'vuetify/es5/locale/zh-Hans'

// Usage -> {{$vuetify.lang.t('$vuetify.close')}} -> 關閉
export default {
  ...zhHans,
  __General: {
    submit: '提交'
  },
  __InitialLoading: {
    connecting: '连接中...'
  },
  __ChatBubbleAi: {
    answer_by_mrc: 'MRC 智能回答',
    mrc_answer_desc: 'MRC (机器阅读理解) 是一种利用机器快速阅读文本，并摘录内容要点的技术。 ',
    answer_by_chatGPT: 'ChatGPT 智能回答',
    chatgpt_answer_desc: 'ChatGPT 是一款由 OpenAI 开发的 AI 聊天机器人，透过自然语言处理技术生成模拟人类的对话内容。 ',
    prompt_bar_message: '以上的内容能帮到你吗？',
    comment_dialog: {
      title: '能说说您给这个评分的原因吗？',
      yes: '好的',
      no: '不用了',
      placeholder: '在此填写内容',
      remaining_character: '剩余字元: {0}/{1}',
      submit: '提交',
      closing: '感谢你的意见！'
    }
  },
  __Cards: {
    highly_relevant: '高度相关',
    moderately_relevant: '中等相关',
    slightly_relevant: '稍微相关',
    client_list: '客户名单',
    success_cases: '成功案例'
  },
  __ChatContainer: {
    today: '今天'
  },
  __InputBar: {
    placeholder: '在此输入讯息...',
    press_to_record: '按住录音',
    recording: '录音中...'
  },
  __Rating: {
    your_rating: '请评分',
    like: '喜欢',
    dislike: '不喜欢',
    field_placeholder: '能告诉我们您给这个评分的原因？',
    scale_rating_hint: '最高 5 分 ，最低 1 分'
  },
  __DateTime: {
    selected: '你已选择',
    select_date: '请选择日期',
    select_time: '请选择时间'
  },
  __Dropdown: {
    select: '请选择'
  }
}
