<template>
  <v-app>
    <v-main>
      <Header v-if="$route.name !== 'Disclaimer'" />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import global_config from '@/../config.json'
import Header from '@/components/Navbars/Header'

export default {
  name: 'App',
  components: { Header },
  data() {
    return {
      config: global_config
    }
  },
  created() {
    let root = document.querySelector(':root')
    let hover_color
    if (this.config.main_color.includes('#')) hover_color = this.config.main_color + '0d'
    else if (this.config.main_color.includes('rgb')) hover_color = this.config.main_color.replace(')', ', 0.05)')
    root.style.setProperty('--hover_color', hover_color)
  }
}
</script>

<style>
:root {
  --hover_color: #ffffff;
}

/* Global Css */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
* {
  font-family: 'Noto Sans', sans-serif !important;
}

.v-application--wrap {
  min-height: 100%;
}

html,
body,
.v-application,
.v-main {
  height: 100%;
  overflow-y: hidden !important;
}

/* v-dialog */
.v-dialog:not(.v-dialog--fullscreen) {
  border-radius: 1rem !important;
}

/* v-btn */
.v-btn {
  letter-spacing: normal !important;
}

.v-btn:not(.v-btn--round).v-size--default {
  min-width: 0 !important;
}

.chat-widget {
  margin-bottom: 0.5rem !important;
}

.chat-btn {
  max-width: 15.1875rem;
  white-space: initial;
  font-weight: 400 !important;
  font-size: 0.9375rem !important;
  line-height: 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 0.75rem !important;
  padding: 0.625rem 1rem;
  text-align: start;
  min-height: 2.75rem !important;
}

.submit-btn {
  float: right;
  height: 2.75rem;
  border: 0.0625rem solid rgba(0, 0, 0, 0.2);
  border-radius: 0.75rem;
}

.submit-btn-text {
  padding-left: 0.5rem !important;
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.5rem;
}

.ellipsis-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ai-response-bubble {
  max-width: 16.5rem !important;
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 140%;
  color: #242428;
  background: #f0f0f0;
  border-radius: 0 0.9375rem 0.9375rem 0.9375rem;
  padding: 0.75rem !important;
}

.plus-btn {
  font-size: 1.5rem !important;
}

.hover-btn:hover {
  background-color: var(--hover_color);
}

/* card or list */
.elevated-card {
  background: #ffffff;
  box-shadow: 0 0.25rem 0.3125rem rgba(0, 0, 0, 0.14), 0 0.0625rem 0.625rem rgba(0, 0, 0, 0.12), 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2) !important;
  border-radius: 1rem;
}

/* time picker */
.time-picker > .dropdown > .select-list > .hours > .active,
.time-picker > .dropdown > .select-list > .minutes > .active,
.time-picker > .dropdown > .select-list > .hours > :not([disable]).active,
.time-picker > .dropdown > .select-list > .minutes > :not([disable]).active {
  background-color: #1b65f2;
}

/* v-tooltip */
.v-tooltip__content {
  opacity: 1 !important;
}

.tooltip-color {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.01875rem;
  color: #ffffff !important;
  background-color: rgba(0, 0, 0, 0.6) !important;
  border-color: rgba(0, 0, 0, 0.6) !important;
  border-radius: 0.5rem;
}

.tooltip-top::before {
  border-right: solid 0.5rem transparent;
  border-left: solid 0.5rem transparent;
  transform: translateX(-50%);
  position: absolute;
  z-index: -21;
  content: '';
  top: 100%;
  left: 50%;
  height: 0;
  width: 0;
}

.tooltip-top.mrc-tooltip::before {
  left: 40%;
}

.tooltip-top.tooltip-color::before {
  border-top: solid 0.5rem rgba(0, 0, 0, 0.6);
}

/* scroll */
/* width */
::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 4px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0, 0.45);
  border-radius: 4px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 0, 0, 0.6);
}

/* set global font size */

/* 4K */
@media only screen and (min-width: 2160px) {
  :root {
    font-size: 24px;
  }
}

@media only screen and (max-width: 350px) {
  :root {
    font-size: 14px;
  }
}
</style>
