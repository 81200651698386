import en from 'vuetify/es5/locale/en'

// Usage -> {{$vuetify.lang.t('$vuetify.close')}} -> 關閉
export default {
  ...en,
  __General: {
    submit: 'Submit'
  },
  __InitialLoading: {
    connecting: 'Connecting...'
  },
  __ChatBubbleAi: {
    answer_by_mrc: 'Answer by MRC',
    mrc_answer_desc: 'MRC (Machine Reading Comprehension) scans text documents and extracts meaning from text',
    answer_by_chatGPT: 'Answer by ChatGPT',
    chatgpt_answer_desc: 'ChatGPT is an AI chatbot developed by OpenAI that uses natural language processing to create human-like conversational dialogue.',
    prompt_bar_message: 'Is this content helpful?',
    comment_dialog: {
      title: 'Would you share the reason for the rating?',
      yes: 'Yes',
      no: 'No',
      placeholder: 'Write your comment here',
      remaining_character: 'Remaining Character: {0}/{1}',
      submit: 'Submit',
      closing: 'Thanks for your comment!'
    }
  },
  __Cards: {
    highly_relevant: 'Highly relevant',
    moderately_relevant: 'Moderately relevant',
    slightly_relevant: 'Slightly relevant',
    client_list: 'Client List',
    success_cases: 'Success Cases'
  },
  __ChatContainer: {
    today: 'Today'
  },
  __InputBar: {
    placeholder: 'Type your message here...',
    press_to_record: 'Hold to Record',
    recording: 'Recording...'
  },
  __Rating: {
    your_rating: 'Your Rating',
    like: 'Like',
    dislike: 'Dislike',
    field_placeholder: 'Share your reason for the rating',
    scale_rating_hint: '5 = Highest, 1 = Lowest'
  },
  __DateTime: {
    selected: 'You have selected',
    select_date: 'Select date',
    select_time: 'Select time'
  },
  __Dropdown: {
    select: 'Please Select'
  }
}
